import React, {useEffect} from "react";
import GeneralBanner from "../Components/GeneralBanner";
import about_pastor from "../Images/about_pastor.jpeg";
import about_us from "../Images/about_us.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutPastor = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, [])

  return (
    <>
   
      <GeneralBanner title="About the Pastor" pageName="About"/>

      <div className="Container mt-10 text-sm text-justify about_content about-content-num overflow-hidden">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div>
            <img src={about_pastor} alt="about us" className="rounded shadow-md shadow-primary" data-aos="fade-right"/>
          </div>

          <div data-aos="fade-left">
            <h3 className="font-semibold text-lg pb-2 uppercase">
              ABOUT THE FOUNDER
            </h3>
            <p>
              I happen to be a young woman who was born into the family of late
              Mr. Bassey Sam of Onna Local Government Area of Akwa Ibom State on
              the 8th of May, 1983.
            </p>
            <h3 className="font-semibold text-lg pb-2 uppercase">GROWING UP</h3>
            <p>
              I grew up mostly with my grandmother, late Mrs Adiaha Imoh of
              Edohoeket family in Eket Local Government Area of Akwa Ibom State.
              My grand mum was a strong believer who started in the Mount Zion
              Church until she was converted to join the BIBLE Standard Church
              by one of her daughters. She remained there until she answered the
              call of her creator. I can practically say that growing up staying
              with my grand mum, my Aunty, Philomena and my amazing mother was
              what actually contributed to my early spiritual growth and
              upbringing and understanding of the things of God.
            </p>
          </div>
          <div>
            <p>
              <b>2. </b> 
              Concerning the things of God, my Dad was not really a strong
              believer and I was not close to him. Growing up with my
              grandmother, who was spiritually inclined really gave me the
              privilege of knowing God and the things of God at a young age.
              Even as at the age of 5, my grand mum always takes me to early
              morning prayer meeting and fasting in the Mount Zion Church. It
              continued until when one of my Aunty, Pastor Mrs Essien Eshiet
              joined The Bible Standard Church founded by Pastor Umoh Akpan
              Ekwo. At this stage of my life, I was already beginning to get
              used to believing in praying, singing and clapping of hands, and
              being a child of God. Whenever it was time to go to Church, I was
              always excited. To the extent that I started gathering children
              around me and leading them in songs and prayers. All these
              happened during my primary school years while staying with my
              Aunty in my grandmothers&#39; house. The commitment of my
              grandmother also rubbed on me and my other cousins formed the core
              of the children Sunday school class.
            </p>
          </div>
          <div>
            <p>
            <b>3. </b> 
              I gained a lot while worshipping with Bible Standard Church. In
              fact, I would say, it was here that the fear of God was instilled
              into me at that young age. They emphasized on new birth and holy
              living, without which no man will see the Lord. It was here that I
              became conscious of eternity (Heaven) and the fear of hell fire. I
              was also blessed when I was opportuned to stay with my Mum. She
              was a member of the Apostolic Church, a committed member and
              belonged to the women fellowship of the Church. What really
              thrilled me about the Apostolic Church was the spiritual move of
              God and the outpouring of the gift of the Holy Spirit, the awesome
              manifestation of the gift of the Holy Spirit upon the life of
              their Elders, Deacons and other serious and committed members both
              men and women and even children. It was an amazing experience and
              I began to pick interest and inwardly desire to be baptized with
              these gifts. I decided to join the prayer group and training
              organised weekly for people that are believing God for the
              outpouring of this wonderful gifts. I also became serious in the
              children choir and also the children Sunday School group.
            </p>
          </div>
          <div>
            <p>
            <b>4. </b> 
              In one of the Saturday evening services, Elder Udofia of Ikot
              Ibiok district after the wonderful teaching on purification &amp;
              Sanctification of the heart, the power and the functions of the 9
              gifts of the Holy Spirit, the man of God lead us into a session of
              prayers with songs 2 and we were all in one accord in His
              presence. I had an experience of inexplicable power like a mighty
              wind and it swept me off my feet and I lost consciousness of my
              physical self. It was later I was told that I spoke in an unknown
              tongue. I knew I was soaked with this outpouring and when I
              regained consciousness, I was very shy because of people that were
              staring at me. But ever since then, that experience marked the
              turning point in my life. Getting home that night, I couldn&#39;t
              sleep, but was still studying the Bible and it became more real
              and interesting to me. After the encounter, I had this inner peace
              that emanate from the inside that I cannot really explain. All
              these happened at my twelve years in my JS 1, second term. Ever
              since then, I started exhibiting this character and attitude that
              made me to be different from other children. My prayer life, my
              studying life and even the things in the school became so clear to
              me after this encounter.
            </p>
          </div>
          <div>
            <p>
            <b>5. </b> 
              Also, my dream life became alive and I am always victorious in my
              dreams. I started to develop this strong desire to know more about
              God and have this deep fear of God in the inside of me that kept
              growing and keep me going. At this stage I love to involve God in
              everything I do, talking to Him and totally depending on His will
              and direction. I believed that he is the only one that understand
              me and the best solution I have. In the course of life, change of
              environment, school and marriage, I have been opportune to worship
              God in other Ministries which also in one way or the other,
              impacted upon my life.
            </p>
            <ul className="list-disc pl-4">
              <li>
                Christian Methodist Episcopal Church, Eket, Akwa Ibom State
              </li>
              <li>Divine Valley Ministry, Eket, Akwa Ibom State</li>
              <li>
                Mustard Seed Assemblies International, 10, Akpan Etuk Street,
                Uyo. Akwa Ibom State
              </li>
              <li>
                Foundation Faith Ministry, also known as Salem International
                Christian Centre, Lekki, Lagos.
              </li>
            </ul>
            <p>
              At Salem International, that was where the journey started that
              have landed me where I am today
            </p>
          </div>
          <div>
            <img src={about_pastor} alt="about us" className="rounded" />
          </div>
          <div>
            <h3 className="font-semibold text-lg pb-2 uppercase">
              MARRIED LIFE
            </h3>
            <p>
            <b>1. </b> I got married in Mustard Seed Assemblies in Uyo, Akwa Ibom State
              on the 31st March 2007. It was my husband that introduced me to
              the Church shortly before our wedding. Here because of my love for
              the things of God, I quickly joined the choir and the prayer band
              group. Barely three months after our wedding, my husband got a job
              that made us to relocate to Lagos from Akwa Ibom in June, 2007.
              That was how we joined Salem International Christian Centre, then
              it was called Foundation Faith Church which originated in Port
              Harcourt led by Arch Bishop Sam Amaga. It took us about two months
              after we arrived in Lagos, to locate this Church. So, coming to
              Lagos for the first time to stay was something I didn&#39;t
              bargain for. It really took me sometime to accept and make up my
              mind to cope and adjust to whatever I see in Lagos. It was in the
              year 2008, around August to October, Salem was having a three-day
              women conference and by then I have already joined the women
              choir.
            </p>
          </div>
          <div>
            <p>
            <b>2. </b> 
              After the program on Sunday service, I heard a voice speak to me
              so audible that &quot;go to the leaders of this Ministry, talk to
              them to give you at least one day in a week, I have deposited so
              much in you and I will use you to loose and deliver those that are
              under satanic bondage, I will use you to tackle stubborn issues
              and uproot them from the root to the glory of my name&quot; I
              smiled in the inside of me and I started asking God whether I
              heard well or not. This thought is too heavy for me to bear. Who
              will believe me? When did I come to Lagos? When did I join Salem?
              Which Bible school did I go to? How do I start the discussion? It
              was actually a burden in my heart and it was too heavy for me to
              bear, I began to argue with God and wrestle away such thoughts as
              if it was my flesh that was playing tricks on me. I continued
              turning the thoughts in my mind until we got home and immediately
              shared it with my husband. I was saying, had it been it&#39;s a
              small Church, I would have known how to go about it, but as big as
              Salem is, who will believe my story? My husband encouraged me to
              start by getting close to the wife of the Bishop and from there
              tip in the discussion. It was definitely not easy with me, from
              one week to three months,
            </p>
          </div>
          <div>
            <p>
            <b>3. </b> 
              I was still battling with these thoughts. I had no peace in the
              inside of me. Shortly, things begin to happen in quick succession,
              people around me, especially the women, lost their pregnancy, some
              had still-birth, some even lost their lives in the process of
              giving birth. There was this particular woman that after losing
              the baby, I cried all the way from Church to the house and I heard
              a voice told me, that it is as a result of my stubbornness, that
              made those women to lose their babies. All efforts in trying to
              talk to some people in Church to let them understand where I was
              coming from proved abortive, I had to decide personally on what to
              do, meanwhile the message kept on coming so strong in my spirit. I
              made up my mind to start praying personally for people wherever
              they may be. The very first day I decided to start praying for
              people, it was on a Thursday, I had a definite encounter of me
              wrestling with powers and demons over the life's and destinies of
              men. I was interceding and praying for all men/women all over the
              world especially those that put all their trust in God and the
              devil is contending with their joy, let God arise, fight and
              destroy them.
            </p>
          </div>
          <div>
            <p>
            <b>4. </b> 
              This prayer was serious and as real as if I saw the devil one on
              one and were fighting for the freedom of women and their babies in
              the womb, crying to God to deliver and set the destinies of men
              free from every attack and let His original plans according to the
              book of Genesis 1:26-30 be fulfilled and restored. My bone of
              contention on that fateful Thursday was that the original glory
              and purpose of God for mankind has been completely shattered and
              altered by the devil and he is busy inflicting pain and sorrows in
              the destinies of men. Going through the scriptures, I discovered
              that Gods original purpose for man was gloriously pronounced and
              destined, but the devil whose battle has always been adulteration
              and altering of God's original plans and purpose, has been the one
              altering everything in the life and destinies of men to suit his
              wicked plot, plans and purpose. That was how I entered into an
              agreement prayer with the God of heaven and earth to forgive my
              stubbornness, refusal and flimsy excuses and reasons. That from
              that day henceforth, I don't need to wait for any approval from
              Salem again, but that I'll be praying personally for all pregnant
              women, expectant mothers and all those believing God for the fruit
              of the womb all over the world.
            </p>
          </div>
          <div>
            <p>
            <b>5. </b> 
              That decision marked the turning point in my life. It was a
              personal covenant with my creator, my pursuit was to see humanity
              fulfil that original God's ordained plan and purpose and not to be
              reduced and suppressed by the demonic and satanic plans of the
              enemy. Because man was created to dominate and subdue the earth.
              That was how I started my quiet time in February, 2009. I entered
              into an agreement with God that I will be interceding for people
              all over the world, irrespective of the fact that whether they
              know me or not. They don't even need to know me but as long as
              they are sober in their spirit at the time I am releasing the
              prayers into their spirit, God will use those prayers and perfect
              his miracle in the lives of the people. How did I know that those
              prayers were working? The very first time I started the prayers,
              interceding for people who the devil has marked for destruction,
              my first assignment was to go and pray for two pregnant women. One
              of them my neighbour while the other my colleague in school (Lagos
              State University, Lekki Campus). These two women were pregnant but
              didn't have the idea of their due month. To approach these two
              women and seek for their permission to pray was not an easy task
              at all. but since I have to obey the instruction of my Father, I
              summoned courage and went firstly to my neighbour's house, so that
              her journey will be smooth, God will see her through and bring her
              back safely with a safe mother and child.
            </p>
          </div>
          <div>
            <p>
            <b>6. </b> 
              I saw the mother first and requested to speak to the daughter. I
              told her what the Lord send me to do, to pray with her and commit
              this journey into the hands of God for safe delivery without
              complications. She didn't give me a good welcome, she underrated
              me and looked down on me. I asked her if she believed in anointing
              oil, which she answered in the positive. I later told the mother
              to use the oil and pray and anoint her tummy. I left. To my
              greatest surprise, the following morning at around 10am, the
              landlord called to say that my neighbour has put to bed. I was
              sent to two people but from the experience I had from the first
              woman, I couldn't sum up courage to go and see the second woman. I
              was contemplating within me, is this what I'll be going through?
              that people will not believe me rather they will be sizing me up?
              But after the testimony of the first woman, it was a source of
              encouragement to me to continue, that means this prayer works!
              When my neighbour came back from the hospital, I went to see her
              and she confessed that I should forgive her, that she actually
              mocked me after I had left. That immediately I left, she can
              actually testify that of a truth, God really sent me to her.
            </p>
          </div>
          <div>
            <p>
            <b>7. </b> 
              So, with this testimony, I was encouraged and I continued with my
              quiet time every Thursday from that Feb 2009 till Feb, 2012. All
              these while it was only my husband and some few women that I
              prayed for that knew what I had been doing. I can recall praying
              for one Sis Anthonia, whose marriage was on the verge of collapse,
              the husband has already told her to pack her things and go, after
              having grown up children in University and secondary schools. But
              from the day I prayed with her in my house, it was an instant
              testimony. According to her, on her way home that day after the
              prayers, she saw her husband on the way (this is the same man that
              told her to pack out) and the man was trying to hug & embrace her
              on the road, she was like embarrassed, couldn't believe what was
              happening. To her utmost surprise, the man was saying, you are my
              wife now, I can hug you anyway I choose to. It was a big surprise
              to her, and till today the marriage is still intact. The other
              lady too had a testimony of healing and deliverance from spiritual
              husband as she fell under anointing and started shouting, it was
              still a mystery to me because I was just following the
              instructions from God.
            </p>
          </div>
          <div>
            <p>
            <b>8. </b> 
              In between these 3 years of my personal intercessory prayers for
              people all over the world, God has been faithful in confirming His
              words with signs and wonders. Space and time will not permit me to
              start mentioning cases upon cases. There was this case of a baby
              about two to three years that has not been walking. She had always
              been carried at the back. At close examination, I noticed that the
              young girls' legs had no life in it. They were limb, no flow of
              blood, the legs had started peeling as if it was like a dry fish.
              It was kind of semi-paralysis. From the waist down, there was no
              life. I embarked on seven-day prayers for the little girl, praying
              and massaging the legs until they came back to life to the glory
              of God. It got to the stage women that were around me conceived
              anyhow. At a point I was leading a group of about 12 women
              including myself who were carrying their babies. when the delivery
              period came, it was week after week of deliveries all to the glory
              of God. All the women close to where I was doing my business, then
              in Sango-tedo market, all got pregnant even to those that had
              waited for up to 3-5 years. Anybody in my company contacted the
              spirit of pregnancy and I was able to confirm that the prayers
              were actually working.
            </p>
          </div>
          <div>
            <p>
            <b>9. </b> 
              I shared my experiences with a Pastor friend & he invited me to
              join their fellowship and intercede for people and there was this
              lady, sister Peace that had the issue of miscarriage, she was told
              that next time she misses her monthly circle, she should call on
              any church elder, to pray so that the baby will survive in her
              womb for the next 9 months. In the space of three weeks, the lady
              called me saying that it's like she had missed her cycle. I
              directed her to my house, joined faith with her in prayers and to
              the glory of God, at the appointed time, she had her baby
              peacefully.
            </p>
            <h3 className="font-semibold text-base pb-2 uppercase">
              HOW DID THE FELLOWSHIP START, THAT LED TO A FULL-FLEDGED CHURCH
              TODAY?
            </h3>
            <p>
            <b>1. </b> 
              On the 19th of February, 2012, I was on my way from Church with my
              husband and children when I saw this woman rushing under hot sun,
              asked her Mummy Prince, what's the matter, where are you rushing
              to? She said her children are not feeling fine & that she's
              looking for where to buy drugs for the children.{" "}
            </p>
          </div>
          <div>
            <p>
            <b>2. </b> 
              She said there is this her neighbour that normally gave her
              children sweets everyday and if they don't collect it, it becomes
              a problem. I dedicated water and gave the children, they left. On
              their way going home, the son asked the mum, who is that lady?
              that the pains have ceased. The second child also confirmed that
              the pains have gone and that prayer has healed them. The next
              morning, Monday, while in my business place, this woman (Mummy
              Prince) walked in with her friend, they were coming back from a
              popular prayer meeting for women. Her friend was another woman
              with pregnancy and wanted me to pray for her. I told her I cannot
              pray here in the market that she should bring her to my house on
              Thursday during my quiet time. I also invited another lady that
              shared her conception experience with me to come on Thursday, lets
              join our faith together and pray. On that fateful Thursday,
              23/02/2012, Mummy Prince brought her friend, Mummy Daniel and the
              other lady (Mrs Emeka) that shared her experience with me also
              came. We were four in number. We prayed generally together but
              when I started praying for them one by one, I laid my hands on her
              tommy, it was like my breath was leaving me & beckoned on the
              other women to pray for me.
            </p>
          </div>
          <div>
            <img src={about_us} alt="about us" className="rounded" />
          </div>
          <div>
            <p>
            <b>3. </b> 
              We finished and dismissed that day. In the morning the following
              day, I got a call from this woman (Mummy Daniel) that when she got
              home, her baby started kicking which before now, she didn't used
              to feel any of her baby's movement. She later pleaded with me that
              my quiet time will not be done alone, that they would love to join
              me during that time. In the local parlance "wetin dey for Sokoto,
              e dey for sokoto" meaning what you are busy looking for in far
              distance is just within your reach. That was the first Thursday
              with people, subsequently, the number started increasing with
              people calling other people to join. During the second week
              Thursday of my quiet time, other women that were invited also
              joined me in my parlour. Not quite long when we started, Prophet
              Ekam of Holy Ghost Apostolic, Jakande area, Lagos, walked in with
              three of his members. According to him, he just came to say hello
              to us and it happened to be that day of my prayers. So, at that
              point, all of us joined together and prayed and he made some
              prophetic declarations about me. By the third week, Sister Ugochi
              brought another sister (Sister Mary) that happened to be my
              witness the day I had my first encounter with a little girl in the
              shop.
            </p>
          </div>
          <div>
            <p>
            <b>4. </b> 
              She was the customer that was in my shop that afternoon. It was an
              awesome experience for the two of us as I beckoned on her to look
              at the girl in the opposite shop, she was being carried but her
              legs didn't have life. The next thing I heard a voice telling me
              to go and pray for the child because what is happening to the
              child is not ordinary. The baby girl was about 2 years plus.
              During the prayers, God opened my eyes to see the body of the
              little girl like an X-ray and I was given insight as to go about
              massaging the legs. It was another level of spiritual grace and
              anointing for me. To the glory of God, the little girl finally
              dropped her legs and walked even before seven days of prayers and
              massaging. During the course of praying for the girl, God actually
              revealed a lot of things to me concerning the mother which I
              relayed to her then. But because of who the devil is, the woman
              today sees me as her arch-enemy and calling all sorts of names.
              But I take it all for joy.
            </p>
          </div>
          <div>
            <p>
            <b>.5 </b> 
              That was why when Sis Ugochi invited Sis Mary to my house and she
              saw that I was the one conducting the fellowship, she didn't doubt
              it at all. She was even more at home that every other woman based
              on her encounter and experience in the case of that little child.
              She was tremendously blessed and after eight years of marriage
              without an issue, now she is a proud mother. All thanks to God
              Almighty for giving her the grace to persevere until she had her
              testimony. That was how my personal quiet time metamorphosed into
              a fellowship and it was experiencing growth every week. Before
              long, it grew to about 70 people in my parlour and the news of
              testimonies and miracles went out throughout sango tedo. Another
              testimony was another baby that was more than a year without
              walking. After praying for her and we dismissed for that day. Few
              people were still around wanting to see me for personal issues,
              when my phone rang and the mother shouted on the other side that
              Anita has started walking! This is the baby I just prayed for. The
              environment was lightened up, and nobody that attended the
              fellowship with an open heart without having a testimony. Things
              were happening in quick succession and my name was everywhere,
              some people even doubted and debated about me even when passing on
              the streets. I still remained my humble self, reserved but the
              testimonies were going ahead of me in the whole area.
            </p>
          </div>
          <div>
            <p>
            <b>6. </b> 
              In one of the fasting held for the pregnant women and people
              believing God for the fruit of the womb, By the spirit of God, I
              told two ladies that am seeing babies in their womb, they did not
              believe me. I said they should go and run pregnancy tests, one of
              them confirmed positive (Sister Peace) while the second one was
              confirmed negative. She was even on tears when she called from the
              lab. I told her relax, please give me three months, if it is God
              that showed me that pregnancy, He will prove himself. To the glory
              of God, the two ladies delivered the same day, same time and also
              same sex in two different hospitals. Those children are big boys
              now to the glory of God, waxing strong in wisdom and
              understanding. What actually increased the population of the
              fellowship was the testimonies and the confirmation that God
              answers prayers. I was somehow exposed to so many people from my
              humble and quiet person, I love the Lord but didn't bargain for
              being singled out as a leader of a fellowship, a founder or even
              running a full-fledged Church. It was never in my plan at anytime,
              didn't budget for it at all. I believe in maintaining a low
              profile, reserved and not having friends. Opening up will
              introduce gossip and backbiting which I so much hate with passion.
              So I prefer keeping to myself and living in my shell but the
              fellowship has so much exposed me to all manners of people with
              wonderful characters and attitudes, which originally will never in
              this life have anything to do with them.
            </p>
          </div>
          <div>
            <p>
            <b>7. </b> 
              From my young age of twelve years, with the truth in me, when I
              tried to relate with people, they are not straight forward, fake
              and full of pretence, such things irritate me so much that to
              avoid such a life, I rather be on my own and do the little I can
              do for God. I don't also believe in forming cliques which is not
              healthy for me. It will give rise to gossiping and backbiting. It
              was not easy for me to relate and cope with people whose mind set
              and character is completely different from mine. Some of these
              things started playing out even as a child. One of my favourite
              forms of play was to gather little children to sing, clap hands
              and to pray. So, when the real signs begin to show up, I was
              seriously afraid because I didn't want to be a Pastor. In one of
              the evening services at my 19 years, by the leading of the Holy
              Spirit, the Pastor called me and anoint my hands and lips that God
              is going to use me for signs and wonders, that there is so much of
              What God has deposited in me that will take me round the globe
              ministering to people and serving lives. At my 24, another
              powerful revelation came again through another Prophet, Pastor Mrs
              Allen. She said this is an honourable vessel in the hands of God,
              even witches and wizards know her, if you come through this way,
              they will pass the other way.
            </p>
          </div>
          <div>
            <p>
            <b>8. </b> 
              She told my husband that God has given you something special. All
              these words did not mean anything to me as at then. I never wanted
              to become or called a Pastor mainly because of so many atrocities
              that some men of God commit all in the name of God. I did not want
              to be grouped among these people. By their actions, they have
              brought shame and mockery to the body of Christ. That was my
              mentality! My plans were after my education, I should be working
              in an oil company. If I am serious in the work to the extent of
              becoming a minister, I will be possessed with it, because it is
              the manifestation of the power and anointing is so strong and so
              real. I was afraid that if I become so serious and committed, I
              will be seeing things on the street, and I started giving all
              manners of excuses, even to the extent that I refuse to be
              baptized. With my little knowledge, I felt if I baptize, that more
              fire and fresh anointing will be released on me, just picturing
              when our Lord Jesus Christ was being baptized. I was giving God
              excuses, that until I am 30, by that time I must have finished
              giving birth to all my children. But before I was 30, the
              fellowship had already started in my house. God was using the
              fellowship to prepare me.
            </p>
          </div>
          <div>
            <p>
            <b>9. </b> 
              I got baptized in August 2013 in Salem International Christian
              Centre, Lekki, Lagos when I was 30. Though I was exactly 30 by May
              2013. I told God that I really wanted to be sure of this thing and
              I don't want to make a mistake. I should not be the one to force
              myself into Ministry but let it come from your leadership. The
              fellowship continued till 4th Feb, 2015 when we moved to a rented
              empty land. My husband assisted in the payment of the land which
              was less than N1,000,000.00 All this while, I did not receive any
              offering and I even used to feed members any day we dismiss from
              the fellowship. Some people even got angry and left that they have
              never seen a place that so many miracles and testimonies are
              happening and yet they don't collect any money. At that time, I
              was not led to collect offering, there was no need for it, it was
              my sitting room, was not footing any bills until when we wanted to
              rent the land. To me, the primary purpose of the intercessory
              prayers was to pray and loose the destines of men and women from
              satanic and demonic bondages and to set those that are held in
              captives free. It was when we came to the empty land that I now
              saw the need to receive offering just to assist in setting up a
              befitting place of worship. Things became heavy for me and I know
              at this point I needed some financial help.
            </p>
          </div>
          <div>
            <p>
            <b>10. </b> 
              At this point, there came again 3 powerful revelations about me
              from different people. Sister Tessy told me that God showed her
              the 3 stages of the Church. I kicked against the revelation but
              she reminded me of the first stage, the second stage that was
              playing out and it remains the third stage to materialize. Sister
              Tessy have been believing God for the fruit of the womb for like 6
              years and to the glory of God, she had conceived and carried her
              child. We recorded diverse testimonies of conception, healing,
              deliverance, etc during this period. Those testimonies were the
              source of my strength. That same 2015, July 27, we had a two-day
              program, Thursday and Friday all night. It was an awesome
              experience in His presence. That was when we gave the fellowship a
              name as Destiny Fulfilling Ministry. By 2016, God started dealing
              with me again, why am I wasting time and roaming about, instead
              for me to sit down, meanwhile there are sheep roaming about, that
              am supposed to nurture them. Any time I go to Salem on a Sunday
              service, this voice will come to remind me to stop roaming about
              and concentrate on the lost sheep. The message was becoming so
              serious and I made up my mind to start Sunday service by February
              2016. I had an initial opposition from some of the members that I
              wanted to pull members from their Churches. Due to this fact, most
              of the pioneer members stopped coming and I had to pursue and go
              ahead with the plan that God had given me.
            </p>
          </div>
          <div>
            <p>
            <b>11. </b> 
              At this point I needed to involve my Bishop (Bishop Benson
              Oritsejolomisan) of Salem Lagos Diocese and intimate him of my
              plans. It took me about the space of three months for me to be
              able to book an appointment to see him one on one. When the
              opportunity came on the 8th of May, 2016, I discussed with him in
              his office in the company of my husband. This is a man I
              personally had love and respect for. I was under him for a period
              of about nine years and I couldn't just decide to leave like that
              without obtaining his blessings. His response was encouraging,
              that the primary assignment that God has given must be fulfilled,
              every other thing is secondary. 16th of May, 2016, I worshipped in
              Salem, then by 22nd of May, we had a 3-day program that ushered us
              to Sunday service, on the 23rd of May, 2016. Since then till now,
              our God has been awesome, confirming His words with signs and
              wonders, recording diverse and astounding miracles all the way. By
              the 27th of May, 2016, the transfer of the Bishop was announced by
              the Archbishop Sam Amaga and that was how we moved on from Salem
              International.
            </p>
          </div>
          <div>
            <p>
            <b>12. </b> 
              When the new Bishop-elect resumed office, he heard about us and
              sent for us. We honoured the invitation and explained everything
              to him, how it all happened. Actually, the Bishop-elect was not
              there on the day of appointment but the wife stood by, prayed and
              released us to go. By 21st of May, 2017, we had a 3-day program to
              celebrate our first-year anniversary of God's goodness and
              faithfulness. By December, 2017 we completed the Ministry
              registration with CAC. It was during the course of the Ministry
              registration that we realised that the original name given to the
              Church had already been in existence. By Gods special grace, with
              the help of the Holy Spirit, we came up with the present name we
              are answering now.
            </p>
          </div>

         
        </div>
      </div>
    </>
  );
};

export default AboutPastor;
