import React, {useEffect} from "react";
import GeneralBanner from "../Components/GeneralBanner";
import about from "../Images/about_us.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutChurch = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <>
     
      <GeneralBanner title="About Us" pageName="About"/>

      <div className="Container mt-10 text-sm text-justify about_content overflow-hidden">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div>
            <img src={about} alt="about us" className="rounded" data-aos="fade-right"/>
          </div>

          <div data-aos="fade-left">
            <h3 className="font-semibold text-lg pb-2 uppercase">
              1. We Believe in One God
            </h3>
            <p>
              We believe in one God as the creator and ruler of the universe. We
              believe that God eternally exist in three persons: God the Father,
              God the Son and God the Holy Spirit. The three in one God as the
              Alpha and the Omega, 2 Cor. 13 vs. 14 The grace of the Lord Jesus
              Christ, and the love of God, and the communion of the Holy Ghost,
              be with you all. Amen As recorded in the book of Gen. 1:1-31; Gen.
              1:1-31 In the beginning God created the heaven and the earth.
            </p>
            <p>
              2 And the earth was without form, and void; and darkness was upon
              the face of the deep. And the Spirit of God moved upon the face of
              the waters. 3 And God said, let there be light: and there was
              light. 4 And God saw the light, that it was good: and God divided
              the light from the darkness.
              {
                "the light from…: Heb. between the light and between the darkness"
              }
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
          <div>
            <p>
              5 And God called the light Day, and the darkness he called Night.
              And the evening and the morning were the first day.{" "}
              {
                "And the evening…: Heb.And the evening was, and the morning was etc."
              }
              6 And God said, let there be a firmament in the midst of the
              waters, and let it divide the waters from the waters.{" "}
              {"firmament: Heb. expansion"} 7 And God made the firmament, and
              divided the waters which were under the firmament from the waters
              which were above the firmament: and it was so. 8 And God called
              the firmament Heaven. And the evening and the morning were the
              second day.{" "}
              {
                "And the evening…: Heb. And the evening was, and the morning was etc."
              }
            </p>
          </div>

          <div>
            <p>
              9 And God said, Let the waters under the heaven be gathered
              together unto one place, and let the dry land appear: and it was
              so. 10 And God called the dry land Earth; and the gathering
              together of the waters called the Seas: and God saw that it was
              good. 11 And God said, Let the 2 earth bring forth grass, the herb
              yielding seed, and the fruit tree yielding fruit after his kind,
              whose seed is in itself, upon the earth: and it was so.
              {"grass: Heb. tender grass"} 12 And the earth brought forth grass,
              and herb yielding seed after his kind, and the tree yielding
              fruit, whose seed was in itself, after his kind: and God saw that
              it was good. 13 And the evening and the morning were the third
              day.{" "}
              {
                "And the evening…: Heb.And the evening was, and the morning was etc."
              }
            </p>
          </div>
          <div>
            <p>
              14 And God said, let there be lights in the firmament of the
              heaven to divide the day from the night; and let them be for
              signs, and for seasons, and for days, and years:{" "}
              {"the day…: Heb. between the day and between the night"} 15 And
              let them be for lights in the firmament of the heaven to give
              light upon the earth: and it was so. 16 And God made two great
              lights; the greater light to rule the day, and the lesser light to
              rule the night: he made the stars also.{" "}
              {"to rule the day…: Heb. for the rule of the day, etc."} 17 And
              God set them in the firmament of the heaven to give light upon the
              earth, 18 And to rule over the day and over the night, and to
              divide the light from the darkness: and God saw that it was good.
              19 And the evening and the morning were the fourth day.{" "}
              {
                "And the evening…: Heb. And the evening was, and the morning was etc."
              }
            </p>
          </div>
          <div>
            <p>
              20 And God said, Let the waters bring forth abundantly the moving
              creature that hath life, and fowl that may fly above the earth in
              the open firmament of heaven. {"moving: or, creeping"}{" "}
              {"life: Heb. soul"} {"fowl…:Heb. let fowl fly"}{" "}
              {"open…: Heb. face of the firmament of heaven"} 21 And God created
              great whales, and every living creature that moveth, which the
              waters brought forth abundantly, after their kind, and every
              winged fowl after his kind: and God saw that it was good. 22 And
              God blessed them, saying, be fruitful, and multiply, and fill the
              waters in the seas, and let fowl multiply in the earth. 23 And the
              evening and the morning were the fifth day.{" "}
              {
                "And the evening…: Heb. And the evening was, and the morning was etc."
              }
              24 And God said, Let the earth bring forth the living creature
              after his kind, cattle, and creeping thing, and beast of the earth
              after his kind: and it was so.
            </p>
          </div>
          <div>
            <p>
              25 And God made the beast of the earth after his kind, and cattle
              after their kind, and everything that creepeth upon the earth
              after his kind: and God saw that it was good. 3 26 And God said,
              let us make man in our image, after our likeness: and let them
              have dominion over the fish of the sea, and over the fowl of the
              air, and over the cattle, and over all the earth, and over every
              creeping thing that creepeth upon the earth. 27 So God created man
              in his own image, in the image of God created he him; male and
              female created he them. 28 And God blessed them, and God said unto
              them, Be fruitful, and multiply, and replenish the earth, and
              subdue it: and have dominion over the fish of the sea, and over
              the fowl of the air, and over every living thing that moveth upon
              the earth. {"moveth: Heb. creepeth"}
            </p>
          </div>
          <div>
            <p>
              29 And God said, Behold, I have given you every herb bearing seed,
              which is upon the face of all the earth, and every tree, in the
              which is the fruit of a tree yielding seed; to you it shall be for
              meat. {"bearing…: Heb. seeding seed"}{" "}
              {"yielding…: Heb. seeding seed"} 30 And to every beast of the
              earth, and to every fowl of the air, and to everything
              thatcreepeth upon the earth, wherein there is life, I have given
              every green herb for meat: and it was so.{" "}
              {"life: Heb. a living soul"}
              31 And God saw everything that he had made, and, behold, it was
              very good. And the evening and the morning were the sixth day.{" "}
              {
                "And the evening…: Heb. And the evening was, and the morning was etc."
              }
            </p>
          </div>

          <div>
            <p>
              In Exodus 3:14; And God said unto Moses, I AM THAT I AM: and he
              said, thus shalt thou say unto the children of Israel, I AM hath
              sent me unto you. In Exodus 20:3-6; 3 Thou shalt have no other
              gods before me. 4 Thou shalt not make unto thee any graven image,
              or any likeness of anything that is in heaven above, or that is in
              the earth beneath, or that is in the water under the earth: 5 Thou
              shalt not bow down thyself to them, nor serve them: for I the LORD
              thy God am a jealous God, visiting the iniquity of the fathers
              upon the children unto the third and fourth generation of them
              that hate me; 6 And shewing mercy unto thousands of them that love
              me, and keep my commandments. 4 We are very resolute and steadfast
              in this believe and our feet is firmly rooted in it like what
              Daniel said in Dan. 3:17-18.
            </p>
          </div>
          <div>
            <p>
              17 If it be so, our God whom we serve is able to deliver us from
              the burning fiery furnace, and he will deliver us out of thine
              hand, O king. 18 But if not, be it known unto thee, O king, that
              we will not serve thy gods, nor worship the golden image which
              thou hast set up. In Upright Life Fulfilling Ministry Int&#39;l,
              we have no business whatsoever with any form or guise of idol
              worship. We believe in God who is all and all. Rev. 1:8; 8 I am
              Alpha and Omega, the beginning and the ending, saith the Lord,
              which is, and which was, and which is to come, the Almighty. In
              Upright Life Fulfilling Ministry Int&#39;l, we believe in the
              original purpose for creation of planet earth and man in his own
              image and likeness. Gen. 1:26-27
            </p>
          </div>
          <div>
            <p>
              26 And God said, let us make man in our image, after our likeness:
              and let them have dominion over the fish of the sea, and over the
              fowl of the air, and over the cattle, and over all the earth, and
              over every creeping thing that creepeth upon the earth. 27 So God
              created man in his own image both male and female created He them.
              The big question every man on earth should ask of his existence is
              why did God create man in His own image different from every other
              creature? I strongly believe if this question is being asked to
              every man, woman, boy and girl, it will help to restore back the
              lost glory of man. The original relationship with God, the
              fellowship between man and God which is found in fulfilling His
              will and completely living in obedience of God&#39;s will. Why God
              created us in His own image:
            </p>
          </div>
          <div>
            <p>
              1) To make man the most important creature among all the creatures
              by giving man&#39;s body a tent for which the soul and the spirit
              can dwell that will enable him fulfil his eternal plans. The
              primary reason why God created man was for Him to reveal His glory
              in man, for man to love Him with all his hearts and obey His will.
              Mark 12:29 -30 29 And Jesus answered him, the first of all the
              commandments is, Hear, O Israel; The Lord our God is one Lord: 30
              And thou shalt love the Lord thy God with all thy heart, and with
              all thy soul, and with all thy mind, and with all thy strength:
              this is the first commandment. Is 60:21; 21 Thy people also shall
              be all righteous: they shall inherit the land for ever, the branch
              of my planting, the work of my hands, that I may be glorified.
            </p>
          </div>
          <div>
            <p>
              John 17:9-10 9 I pray for them: I pray not for the world, but for
              them which thou hast given me; for they are thine. 10 And all mine
              are thine, and thine are mine; and I am glorified in them. John
              21:19 19 This spake he, signifying by what death he should glorify
              God. And when he had spoken this, he saith unto him, Follow me. 2)
              For Dominion: To rule over, control and be in charge of the earth,
              having legal authority and power over principalities, powers of
              darkness and demonic influence of the devil. Gen.1:26 26 And God
              said, let us make man in our image, after our likeness: and let
              them have dominion over the fish of the sea, and over the fowl of
              the air, and over the cattle, and over all the earth, and over
              every creeping thing that creepeth upon the earth. Eph 6:11-16 6
              11 Put on the whole armour of God, that ye may be able to stand
              against the wiles of the devil. 12 For we wrestle not against
              flesh and blood, but against principalities, against powers,
              against the rulers of the darkness of this world, against
              spiritual wickedness in high places.{" "}
              {"flesh…: Gr. blood and flesh"} {"spiritual…: or, wicked spirits"}{" "}
              {"high: or, heavenly"}
            </p>
          </div>
          <div>
            <p>
              13 Wherefore take unto you the whole armour of God, that ye may be
              able to withstand in the evil day, and having done all, to stand.{" "}
              {"having…: or, having overcome all"} 14 Stand therefore, having
              your loins girt about with truth, and having on the breastplate of
              righteousness; 15 And your feet shod with the preparation of the
              gospel of peace; 16 Above all, taking the shield of faith,
              wherewith ye shall be able to quench all the fiery darts of the
              wicked. 3) To enjoy the creation of God: Everything was put in
              place to make man&#39;s life comfortable on earth. Everything was
              fine and good by God. There was no suffering, no death, no battle
              and no pain until the fall of man in the garden of Eden through
              disobedience. Gen. 3:1-19. 1 Now the serpent was subtler than any
              beast of the field which the LORD God had made. And he said unto
              the woman, Yea, hath God said, Ye shall not eat of every tree of
              the garden? {"Yea…: Heb. Yea, because, etc."}2 And the woman said
              unto the serpent, we may eat of the fruit of the trees of the
              garden:
            </p>
          </div>
          <div>
            <p>
              3 But of the fruit of the tree which is in the midst of the
              garden, God hath said, Ye shall not eat of it, neither shall ye
              touch it, lest ye die. 4 And the serpent said unto the woman, Ye
              shall not surely die: 5 For God doth know that in the day ye eat
              thereof, then your eyes shall be opened, and ye shall be as gods,
              knowing good and evil. 6 And when the woman saw that the tree was
              good for food, and that it was pleasant to the eyes, and a tree to
              be desired to make one wise, she took of the fruit thereof, and
              did eat, and gave also unto her husband with her; and he did eat.
              pleasant: Heb. a desire 7 And the eyes of them both were opened,
              and they knew that they were naked; and they sewed fig leaves
              together, and made themselves aprons. aprons: or, things to gird
              about 8 And 7 they heard the voice of the LORD God walking in the
              garden in the cool of the day: and Adam and his wife hid
              themselves from the presence of the LORD God amongst the trees of
              the garden. cool: Heb. wind
            </p>
          </div>
          <div>
            <p>
              9 And the LORD God called unto Adam, and said unto him, Where art
              thou? 10 And he said, I heard thy voice in the garden, and I was
              afraid, because I was naked; and I hid myself. 11 And he said, who
              told thee that thou wast naked? Hast thou eaten of the tree,
              whereof I commanded thee that thou shouldest not eat? 12 And the
              man said, the woman whom thou gavest to be with me, she gave me of
              the tree, and I did eat. 13 And the LORD God said unto the woman,
              what is this that thou hast done? And the woman said, the serpent
              beguiled me, and I did eat. 14 And the LORD God said unto the
              serpent, Because thou hast done this, thou art cursed above all
              cattle, and above every beast of the field; upon thy belly shalt
              thou go, and dust shalt thou eat all the days of thy life: 15 And
              I will put enmity between thee and the woman, and between thy seed
              and her seed; it shall bruise thy head, and thou shalt bruise his
              heel.
            </p>
          </div>
          <div>
            <p>
              16 Unto the woman he said, I will greatly multiply thy sorrow and
              thy conception; in sorrow thou shalt bring forth children; and thy
              desire shall be to thy husband, and he shall rule over thee. to
              thy…: or, subject to thy husband 17 And unto Adam he said, Because
              thou hast hearkened unto the voice of thy wife, and hast eaten of
              the tree, of which I commanded thee, saying, Thou shalt not eat of
              it: cursed is the ground for thy sake; in sorrow shalt thou eat of
              it all the days of thy life; 18 Thorns also and thistles shall it
              bring forth to thee; and thou shalt eat the herb of the field;
              bring…: Heb. cause to bud 19 In the sweat of thy face shalt thou
              eat bread, till thou return unto the ground; for out of it wast
              thou taken: for dust thou art, and unto dust shalt thou return.
              Thank God for our Lord and Saviour Jesus Christ who God sent to
              come as a second Adam to redeem the soul of man from eternal
              destruction.
            </p>
          </div>
          <div>
            <p>
              <b className="text-lg">
                {" "}
                Why do we believe so much in our Lord Jesus Christ?
              </b>{" "}
              In Upright Life Fulfilling Ministry Intl, we believe totally in
              our Lord Jesus Christ as our Redeemer, our Mediator of the new and
              a better covenant. The one who paid the ransome price for the
              freedom of humanity from the bondage of sin to as many that
              believe on him. Hebrew 8:6-12 6 But now hath he obtained a more
              excellent ministry, by how much also he is the mediator of a
              better covenant, which was established upon better promises.
              covenant: or, testament 7 For if that first covenant had been
              faultless, then should no place have been sought for the second. 8
              For finding fault with them, he saith, Behold, the days come,
              saith the Lord, when I will make a new covenant with the house of
              Israel and with the house of Judah: 9 Not according to the
              covenant that I made with their fathers in the day when I took
              them by the hand to lead them out of the land of Egypt; because
              they continued not in my covenant, and I regarded them not, saith
              the Lord.
            </p>
          </div>
          <div>
            <p>
              10 For this is the covenant that I will make with the house of
              Israel after those days, saith the Lord; I will put my laws into
              their mind, and write them in their hearts: and I will be to them
              a God, and they shall be to me a people: {"put: Gr. give"}{" "}
              {"in: or, upon"} 11 And they shall not teach every man his
              neighbour, and every man his brother, saying, Know the Lord: for
              all shall know me, from the least to the greatest. 12 For I will
              be merciful to their unrighteousness, and their sins and their
              iniquities will I remember no more. Heb 9:15-18; 15 And for this
              cause he is the mediator of the new testament, that by means of
              death, for the redemption of the transgressions that were under
              the first testament, they which are called might receive the
              promise of eternal inheritance. 16 For where a testament is, there
              must also of necessity be the death of the testator.{" "}
              {"be: or, be brought in"} 17 For a testament is of force after men
              are dead: otherwise it is of no strength at all while the testator
              liveth. 18 Whereupon neither the first testament was dedicated
              without blood. {"dedicated: or, purified"}
            </p>
          </div>
          <div>
            <p>
              Romans 6:23; 23 For the wages of sin is death; but the gift of God
              is eternal life through Jesus Christ our Lord. Romans 5:12 12
              Wherefore, as by one-man sin entered into the world, and death by
              sin; and so, death passed upon all men, for that all have sinned:{" "}
              {"for that: or, in whom"}
              Romans 3:23 23 For all have sinned, and come short of the glory of
              God. 1 Tim.2:4, 4 Who will have all men to be saved, and to come
              unto the knowledge of the truth. Heb. 10:1-7 1 For the law having
              a shadow of good things to come, and not the very image of the
              things, can never with those sacrifices which they offered year by
              year continually make the comers thereunto perfect. 2 For then
              would they not have ceased to be offered? because that the
              worshippers once purged should have had no more conscience of
              sins.{" "}
              {
                "would…: or, they would have ceased to be offered, because, etc."
              }{" "}
              3 But in those sacrifices, there is a remembrance again made of
              sins every year. 4 For it is not possible that the blood of bulls
              and of goats should take away sins. 5 Wherefore when he cometh
              into the world, he saith, Sacrifice and offering thou wouldest
              not, but a body hast thou prepared me:{" "}
              {"hast…: or, thou hast fitted me"}
            </p>
          </div>
          <div>
            <p>
              6 In burnt offerings and sacrifices for sin thou hast had no
              pleasure. 7 Then said I, Lo, I come (in the volume of the book it
              is written of me,) to do thy will, O God. Luke 4:16-21 16 And he
              came to Nazareth, where he had been brought up: and, as his custom
              was, he went into the synagogue on the sabbath day, and stood up
              for to read. 17 And there was delivered unto him the book of the
              prophet Esaias. And when he had opened the book, he found the
              place where it was written, 18 The Spirit of the Lord is upon me,
              because he hath anointed me to preach the gospel to the poor; he
              hath sent me to heal the broken hearted, to preach deliverance to
              the captives, and recovering of sight to the blind, to set at
              liberty them that are bruised, 19 To preach the acceptable year of
              the Lord. 20 And he closed the book, and he gave it again to the
              minister, and sat down. And the eyes of all them that were in the
              synagogue were fastened on him. 21 And he began to say unto them,
              this day is this scripture fulfilled in your ears.
            </p>
          </div>
          <div>
            <p>
              In John 1:3-4 3 All things were made by him; and without him was
              not anything made that was made. 4 In him was life; and the life
              was the light of men. In Colosians1:16-20 16 For by him were all
              things created, that are in heaven, and that are in earth, visible
              and invisible, whether they be thrones, or dominions, or
              principalities, or powers: all things were created by him, and for
              him: 17 And he is before all things, and by him all things
              consist. 18 And he is the head of the body, the church: who is the
              beginning, the firstborn from the dead; that in all things he
              might have the pre-eminence. {"in…: or, among all"} 19 For it
              pleased the Father that in him should all fullness dwell; 20 And,
              having made peace through the blood of his cross, by him to
              reconcile all things unto himself; by him, I say, whether they be
              things in earth, or things in heaven. {"having…: or, making"}
            </p>
          </div>
          <div>
            <p>
              The Bible records that in Him all things were created, in heaven,
              on earth, visible and invisible; whether thrones or powers or
              rulers or authorities. All things have been created through Him
              and for Him. With this understanding in our spirit, we have no
              other thing or place to believe on except Christ Jesus. We believe
              in the coming into the world of our Lord Jesus Christ. Just as we
              believe in the original glory and purpose of God for mankind and
              the restoration work of our Lord Jesus Christ as covered by the
              new covenant, Heb. 8:1-13 1 Now of the things which we have spoken
              this is the sum: We have such an high priest, who is set on the
              right hand of the throne of the Majesty in the heavens; 2 A
              minister of the sanctuary, and of the true tabernacle, which the
              Lord pitched, and not man.{" "}
              {"of the sanctuary: or, of holy things"} 3 For every high priest
              is ordained to offer gifts and sacrifices: wherefore it is of
              necessity that this man have somewhat also to offer. 4 For if he
              were on earth, he should not be a priest, seeing that there are
              priests that offer gifts according to the law:{" "}
              {"there…: or, they are priests"}
            </p>
          </div>
          <div>
            <p>
              5 Who serve unto the example and shadow of heavenly things, as
              Moses was admonished of God when he was about to make the
              tabernacle: for, See, saith he, that thou make all things
              according to the pattern shewed to thee in the mount. 6 But now
              hath he obtained a more excellent ministry, by how much also he is
              the mediator of a better covenant, which was established upon
              better promises. {"covenant: or, testament"} 7 For if that first
              covenant had been faultless, then should no place have been sought
              for the second. 8 For finding fault with them, he saith, Behold,
              the days come, saith the Lord, when I will make a new covenant
              with the house of Israel and with the house of Judah: 9 Not
              according to the covenant that I made with their fathers in the
              day when I took them by the hand to lead them out of the land of
              Egypt; because they continued not in my covenant, and I regarded
              them not, saith the Lord.
            </p>
          </div>
          <div>
            <p>
              10 For this is the covenant that I will make with the house of
              Israel after those days, saith the Lord; I will put my laws into
              their mind, and write them in their hearts: and I will be to them
              a God, and they shall be to me a people: {"put: Gr. give"}{" "}
              {"in: or, upon"} 11 And they shall not teach every man his
              neighbour, and every man his brother, saying, Know the Lord: for
              all shall know me, from the least to the greatest. 12 For I will
              be merciful to their unrighteousness, and their sins and their
              iniquities will I remember no more. 13 In that he saith, A new
              covenant, he hath made the first old. Now that which decayeth and
              waxeth old is ready to vanish away. We also believe as one who God
              sent through flesh and blood to come and save us from the bondage
              of sin and death. Mathew 1:21 21 And she shall bring forth a son,
              and thou shalt call his name JESUS: for he shall save his people
              from their sins. {"JESUS: that is, Saviour, Heb"}
              We truly believe that Jesus is the way, the truth and the life.
              John 3:16 -21 16 For God so loved the world, that he gave his only
              begotten Son, that whosoever believeth in him should not perish,
              but have everlasting life.
            </p>
          </div>
          <div>
            <p>
              17 For God sent not his Son into the world to condemn the world;
              but that the world through him might be saved. 18 He that
              believeth on him is not condemned: but he that believeth not is
              condemned already, because he hath not believed in the name of the
              only begotten Son of God. 19 And this is the condemnation, that
              light is come into the world, and men loved darkness rather than
              light, because their deeds were evil. 20 For every one that doeth
              evil hateth the light, neither cometh to the light, lest his deeds
              should be reproved. {"reproved: or, discovered"} 21 But he that
              doeth truth cometh to the light, that his deeds may be made
              manifest, that they are wrought in God. Matt. 25:46 46 And these
              shall go away into everlasting punishment: but the righteous into
              life eternal. We believe in His reconciliatory work on the cross
              which broke the chains of spiritual death & hell. Jesus came to
              deliver the captives: Luke 4:18. 18 The Spirit of the Lord is upon
              me, because he hath anointed me to preach the gospel to the poor;
              he hath sent me to heal the broken hearted, to preach deliverance
              to the captives, and recovering of sight to the blind, to set at
              liberty them that are bruised,
            </p>
          </div>
          <div>
            <p>
              19 To preach the acceptable year of the Lord. Through our Lord
              Jesus Christ we have the opportunity of knowing the Holy Spirit.
              Is, 61:1-9. 1 The Spirit of the Lord GOD is upon me; because the
              LORD hath anointed me to preach good tidings unto the meek; he
              hath sent me to bind up the broken hearted, to proclaim liberty to
              the captives, and the opening of the prison to them that are
              bound; 2 To proclaim the acceptable year of the LORD, and the day
              of vengeance of our God; to comfort all that mourn; 3 To appoint
              unto them that mourn in Zion, to give unto them beauty for ashes,
              the oil of joy for mourning, the garment of praise for the spirit
              of heaviness; that they might be called trees of righteousness,
              the planting of the LORD, that he might be glorified. 4 And they
              shall build the old wastes, they shall raise up the former
              desolations, and they shall repair the waste cities, the
              desolations of many generations. 5 And strangers shall stand and
              feed your flocks, and the sons of the alien shall be your plowmen
              and your vinedressers. 6 But ye shall be named the Priests of the
              LORD: men shall call you the Ministers of our God: ye shall eat
              the riches of the Gentiles, and in their glory shall ye boast
              yourselves.
            </p>
          </div>
          <div>
            <p>
              7 For your shame ye shall have double; and for confusion they
              shall rejoice in their portion: therefore, in their land they
              shall possess the double: everlasting joy shall be unto them. 8
              For I the LORD love judgment, I hate robbery for burnt offering;
              and I will direct their work in truth, and I will make an
              everlasting covenant with them. 9 And their seed shall be known
              among the Gentiles, and their offspring among the people: all that
              see them shall acknowledge them, that they are the seed which the
              LORD hath blessed. Acts 2:31-33 31 He seeing this before spake of
              the resurrection of Christ, that his soul was not left in hell,
              neither his flesh did see corruption. 32 This Jesus hath God
              raised up, whereof we all are witnesses. 33 Therefore being by the
              right hand of God exalted, and having received of the Father the
              promise of the Holy Ghost, he hath shed forth this, which ye now
              see and hear. Acts 2:38 38 Then Peter said unto them, Repent, and
              be baptized every one of you in the name of Jesus Christ for the
              remission of sins, and ye shall receive the gift of the Holy
              Ghost. Without Jesus, no man will see God,
            </p>
          </div>
          <div>
            <p>
              John 1:14. 14 And the Word was made flesh, and dwelt among us,
              (and we beheld his glory, the glory as of the only begotten of the
              Father,) full of grace and truth. You have to make up your mind
              from today about accepting Christ as your personal Lord and
              Saviour and everything will become new. 1 Cor. 5:17 17 Therefore
              if any man be in Christ, he is a new creature: old things are
              passed away; behold, all things are become new.{" "}
              {"he is: or, let him be"}
            </p>
          </div>
          <div>
            <h3 className="font-semibold text-lg pb-2">2. THE WORD</h3>
            <p>
              In Upright Life Fulfilling Ministry Int'l, we believe in the
              undiluted Word of God, that the Bible has been given to us only by
              the inspiration of God which is profitable for doctrine, reproof,
              for correction, for instruction in righteousness: that the man of
              God may be perfect, thoroughly furnished unto all good works. 2
              Tim. 3:16
            </p>
          </div>
          <div>
            <p>
              {" "}
              16 All scripture is given by inspiration of God, and is profitable
              for doctrine, for reproof, for correction, for instruction in
              righteousness: 17 That the man of God may be perfect, thoroughly
              furnished unto all good works. {"thoroughly…: or, perfected"}
              In Upright Life Fulfilling Ministry, we believe that the word of
              God is alive and powerful, even sharper than a two-edged sword
              that is capable of cutting between the soul and the spirit and the
              innermost joints and marrow, and is a discerner of the thoughts
              and intents of the heart. Nothing in this whole creation is hidden
              from God, everything is naked, open and exposed before his eyes
              and God is the only one we are all accountable to. Heb. 4:12-13 12
              For the word of God is quick, and powerful, and sharper than any
              two-edged sword, piercing even to the dividing asunder of soul and
              spirit, and of the joints and marrow, and is a discerner of the
              thoughts and intents of the heart. 13 Neither is there any
              creature that is not manifest in his sight: but all things are
              naked and opened unto the eyes of him with whom we have to do. We
              believe that in the beginning was the word and the word was with
              God and the word was God.
            </p>
          </div>
          <div>
            <p>
              John 1:1 1 In the beginning was the Word, and the Word was with
              God, and the Word was God. The same word became flesh and dwell
              among us, and we beheld his glory, the glory as of the only
              begotten of the Father, full of grace and truth. In Upright Life
              Fulfilling Ministry International, we hold firm to the word in our
              spirit and we believe the word is life. Phil 2:16 16 Holding forth
              the word of life; that I may rejoice in the day of Christ, that I
              have not run in vain, neither laboured in vain. We believe that
              the latter-day glorious Ministry shall be greater than the former,
              the time has come for people to put God's word into practice and
              begin to manifest the word, live by the word and become the true
              doers of the word. The Bible says, every other thing shall pass
              away, but the word of God will never pass away. We in Upright Life
              Fulfilling Ministry Int'l build up our trust and believe upon the
              word of God. We are on earth to fulfil the word, which is the will
              of the Father. James 1:19-27 19 Wherefore, my beloved brethren,
              let every man be swift to hear, slow to speak, slow to wrath:
            </p>
          </div>
          <div>
            <p>
              21 Wherefore lay apart all filthiness and superfluity of
              naughtiness, and receive with meekness the engrafted word, which
              is able to save your souls. 22 But be ye doers of the word, and
              not hearers only, deceiving your own selves. 23 For if any be a
              hearer of the word, and not a doer, he is like unto a man
              beholding his natural face in a glass: 24 For he beholdeth
              himself, and goeth his way, and straightway forgetteth what manner
              of man he was. 25 But whoso looketh into the perfect law of
              liberty, and continueth therein, he being not a forgetful hearer,
              but a doer of the work, this man shall be blessed in his deed.{" "}
              {"deed: or, doing"} 26 If any man among you seem to be religious,
              and bridleth not his tongue, but deceiveth his own heart, this
              man's religion is vain. 27 Pure religion and undefiled before God
              and the Father is this, to visit the fatherless and widows in
              their affliction, and to keep himself unspotted from the world.
            </p>
          </div>
          <div>
            <p>
              Phil 4:9 9 Those things, which ye have both learned, and received,
              and heard, and seen in me, do: and the God of peace shall be with
              you. Luke 8:21 21 And he answered and said unto them, my mother
              and my brethren are these which hear the word of God, and do it.
              Mathew 7:24-27 24 Therefore whosoever heareth these sayings of
              mine, and doeth them, I will liken him unto a wise man, which
              built his house upon a rock: 25 And the rain descended, and the
              floods came, and the winds blew, and beat upon that house; and it
              fell not: for it was founded upon a rock. 26 And every one that
              heareth these sayings of mine, and doeth them not, shall be
              likened unto a foolish man, which built his house upon the sand:
              27 And the rain descended, and the floods came, and the winds
              blew, and beat upon that house; and it fell: and great was the
              fall of it. In Upright Life Fulfilling Ministry Int'l, we believe
              in Upright living, in Holiness, in righteousness, in purity of
              heart and living a life of integrity.
            </p>
          </div>
          <div>
            <p>
              Psalms 15:2 2 He that walketh uprightly, and worketh
              righteousness, and speaketh the truth in his heart. 3 He that
              backbiteth not with his tongue, nor doeth evil to his neighbour,
              nor taketh up a reproach against his neighbour.{" "}
              {"taketh…: or, receiveth, or, endureth"}
              Heb 12:14 14 Follow peace with all men, and holiness, without
              which no man shall see the Lord: Prov 21:21 21 He that followed
              after righteousness and mercy findeth life, righteousness, and
              honour. Mark 7:15-23 15 There is nothing from without a man, that
              entering into him can defile him: but the things which come out of
              him, those are they that defile the man. 16 If any man has ears to
              hear, let him hear. 17 And when he was entered into the house from
              the people, his disciples asked him concerning the parable. 18 And
              he saith unto them, are ye so without understanding also? Do ye
              not perceive, that whatsoever thing from without entereth into the
              man, it cannot defile him; 19 Because it entereth not into his
              heart, but into the belly, and goeth out into the draught, purging
              all meats?
            </p>
          </div>
          <div>
            <p>
              21 For from within, out of the heart of men, proceed evil
              thoughts, adulteries, fornications, murders, 22 Thefts,
              covetousness, wickedness, deceit, lasciviousness, an evil eye,
              blasphemy, pride, foolishness:{" "}
              {"covetousness…: Gr. covetousnesses, wickednesses"} 23 All these
              evil things come from within, and defile the man. 1Kings 9:4-5. 4
              And if thou wilt walk before me, as David thy father walked, in
              integrity of heart, and in uprightness, to do according to all
              that I have commanded thee, and wilt keep my statutes and my
              judgments: 5 Then I will establish the throne of thy kingdom upon
              Israel for ever, as I promised to David thy father, saying, There
              shall not fail thee a man upon the throne of Israel. We believe
              our God is a holy God and, in his image, we have all been created
              for his glory and purpose, to live an upright and a perfect life
              which will guarantee our soul eternal rest. For us to be called
              the children of God, we must walk in his uprightness and fear of
              the Lord. Anyone who refuses God's way of life despises God.
            </p>
          </div>
          <div>
            <p>
              Prov 14:2 2 He that walketh in his uprightness feareth the LORD:
              but he that is perverse in his ways despiseth him. Our God is a
              righteous God and He loves righteousness Psalm 11:7 7 For the
              righteous LORD loveth righteousness; his countenance doth behold
              the upright. In Upright Life Fulfilling Ministry Int'l, we believe
              that it is only the righteous that will behold the face of God and
              we serve a God who saves the upright in heart. Psalm 7:10 10 My
              defence is of God, which saveth the upright in heart.{" "}
              {"My…: Heb. My buckler is upon God"}
              We believe that our God loves purity, righteousness and holiness
              of the heart. Mathew 5:8 8 Blessed are the pure in heart: for they
              shall see God. We in Upright Life Fulfilling Ministry believe in
              blameless life and working out our salvation and getting our
              spirit ready for the journey of eternity Phil 2:14-16. 14 Do all
              things without murmurings and disputings: 15 That ye may be
              blameless and harmless, the sons of God, without rebuke, in the
              midst of a crooked and perverse nation, among whom ye shine as
              lights in the world; {"harmless: or, sincere"}{" "}
              {"ye shine: or, shine ye"} 16 Holding forth the word of life; that
              I may rejoice in the day of Christ, that I have not run in vain,
              neither laboured in vain. We believe that without holiness, no man
              will see the Lord
            </p>
          </div>
          <div>
            <h3 className="font-semibold text-lg pb-2">3. THE HOLY SPIRIT</h3>
            <p>
              In Upright Life Fulfilling Ministry Int'l, we believe in the
              person of the Holy Ghost who the Father sent as promise of a
              comforter. The Holy Spirit is a person equal in every way with God
              the Father and God the son, the Holy Spirit is considered to be
              the third member of the Godhead. He has all the divine attributes
              ascribed to the Father and the son. Once a person is born again by
              believing and receiving Jesus Christ as his personal Lord and
              Saviour, God resides in him/her through the Holy Spirit. Three
              attributes of the Holy Spirit are: The intellect, emotions and
              will. One of the primary roles of the Holy Spirit is that it bears
              witness of Jesus Christ, it reveals to the hearts of men the truth
              about our Lord Jesus Christ. The Holy Spirit teaches us how-to
              live-in obedience, it reveals God's will and truth to the
              Christian. The Holy Spirit was sent to live inside of those who
              have genuinely given their lives to Christ, those who have made up
              their minds to live for Jesus in order to produce Gods character
              and ability in the lives of believers. It was sent to convict men
              of their sins and also bring men to repentance.
            </p>
          </div>
          <div>
            <p>
              The Holy was the agent of creation. it was sent to us to be able
              to live a life that portrays the fruits of the Spirit which are
              love, joy, peace, patience, kindness, goodness, faithfulness,
              gentleness and self control. With the help of the Holy Spirit, we
              don't struggle to love one another or to accomplish any other
              fruit of the spirit. The Holy Spirit enables us to flow and
              flourish in God's kind of love without pretence or eye service.
              That is why God demands of us to rely on him to produce and live
              the life that is showing the fruits of the spirit without
              struggle. As Christians we are told to be filled with the spirit
              and to work in the spirit. When once we are empowered by the
              spirit, we will be able to perform all other spiritual activities
              that will increase and promote our spiritual well being.
            </p>
          </div>
          <div>
            <p>
              These are some of the amazing functions of the Holy Spirit in the
              life of believers:
            </p>
            <ul className="list-disc pl-4">
              <li>The Holy Spirit is an amazing helper</li>
              <li>The Holy Spirit is a glorious Comforter</li>
              <li>The Holy Spirit is an amazing great teacher</li>
              <li>The Holy Spirit is a wonderful mentor</li>
              <li>The Holy Spirit is an awesome advocate</li>
              <li>
                The Holy spirit is our Wonderful, Powerful and great weapon of
                warfare
              </li>
              <li>The Holy Spirit is our source of spiritual strength</li>
              <li>
                The Holy Spirit is the source of our dominion power, Authority,
                grace and ability to do the extraordinary. He is our Healer and
                deliverance
              </li>
            </ul>
          </div>
          <div>
            <ul className="list-disc pl-4">
              <li>
                The Holy Spirit is the author of the scriptures: 2Tim3:16; 2
                Peter1:21
              </li>
              <li>
                The Holy Spirit is the link person in enjoying the presence of
                the Father. He is our peace maker.
              </li>
              <li>
                .The Holy Spirit reveals Gods words to our spirit, helps us to
                understand the word, grant us grace to live and do the word,
                live in holiness, purity of the heart and righteousness.
              </li>
            </ul>
          </div>
          <div>
            <p>
              The Holy Spirit is the gift of God to the body of Christ, to help
              bear witness of our Lord Jesus Christ. Acts 1:8 8 But ye shall
              receive power, after that the Holy Ghost is come upon you: and ye
              shall be witnesses unto me both in Jerusalem, and in all Judaea,
              and in Samaria, and unto the uttermost part of the earth.{" "}
              {"power…: or, the power of the Holy Ghost coming upon you."}
            </p>
          </div>
          <div>
            <p>
              2 Cor. 3:17 17 Now the Lord is that Spirit: and where the Spirit
              of the Lord is, there is liberty. In Upright Life Fulfilling
              Ministry Int'l, we believe so much in the gift of the Holy Spirit
              and we know it is the foundation of the Ministry. He is the
              builder of the body of Christ, our leader and instructor, our
              moulder and coordinator. Without the help of the Holy Spirit, no
              man under the surface of this earth will be able to fulfil that
              original Gods ordained destiny unless he/she wants to live a life
              of pretence and fulfil a counterfeit destiny as provided by the
              devil. In Upright Life Ministry, every other belief is built on
              this amazing truth and light from the word in the person of the
              Holy Spirit. I am personally in love with the Holy Spirit in my
              life, without which, life will be so meaningless and frustrating.
              Yielding to the leadership of the Holy Spirit is the best thing
              that has ever happened to the life of any man/woman here on earth.
              Even the devil knows, that's why is trying all within its power to
              deceive men today with counterfeit spirit. Using demonic powers
              and influence to portray the original. The Bible has instructed us
              to test and try all spirits to know the one that is from God.
              Also, with the Spirit of discernment, we will be able to detect
              the false spirit.
            </p>
          </div>
          <div>
            <h3 className="font-semibold text-lg pb-2 uppercase">
              In Conclusion
            </h3>
            <p>
              If you can be able to conquer the devil' s influence and go
              through all the scriptures spiritually, your entire life and
              destiny will be completely transformed for God beginning from
              today. I strongly believe nothing is impossible with God, it can
              be impossible with man, but with God, nothing is above Him. This
              includes whatever situation you find yourself right now, the
              Spirit of God is with you. If only your heart can just in a moment
              yield and surrender to him, the amazing power of the Holy Spirit
              will spring into action immediately for your testimony and to the
              glory of God. Every stubborn situation is encountering the power
              of God through the Holy Spirit right now in Jesus' mighty name.
              Amen John 16:1-15; 1 John 2:20; John 14:26; Luke 2:27; Luke 4:1;
              Acts 8:29; Rom. 8:14; Acts 1:16; Acts 2:1-14; Acts 13:2; Acts
              28:25; Heb. 3:7; Matt. 12:28; 1 Peter 1:2; Eph 1:13-14; John
              14:15-17; Acts 5:1-10; Gal5:16; Acts 4:31; John 3:5-6; Is. 11:2;
              Ezek. 36:26-27; Rom 8:26; Gal 5:22-23
            </p>
          </div>
          <div>
            <h3 className="font-semibold text-lg pb-2">VISION STATEMENT:</h3>
            <p>
              Fulfilling the original God' s ordained destiny through Upright
              living with the help of the Holy Spirit. John 14: 16-17 16 And I
              will pray the Father, and he shall give you another Comforter,
              that he may abide with you forever; 17 Even the Spirit of truth;
              whom the world cannot receive, because it seeth him not, neither
              knoweth him: but ye know him; for he dwelleth with you, and shall
              be in you. Psalm 119:1-7 1 Blessed are the undefiled in the way,
              who walk in the law of the LORD.{" "}
              {"undefiled: or, perfect, or, sincere"} 2 Blessed are they that
              keep his testimonies, and that seek him with the whole heart. 3
              They also do no iniquity: they walk in his ways. 4 Thou hast
              commanded us to keep thy precepts diligently. 5 O that my ways
              were directed to keep thy statutes! 6 Then shall I not be ashamed,
              when I have respect unto all thy commandments. 7 I will praise
              thee with uprightness of heart, when I shall have learned thy
              righteous judgments. {"thy…: Heb. judgments of thy righteousness"}
            </p>
          </div>
          <div>
            <h3 className="font-semibold text-lg pb-2">MISSION STATEMENT:</h3>
            <p>
              Raising men and women who are strong in faith, holding fast unto
              the mission and the finished work of our Lord Jesus Christ; which
              was fulfilling the will of the Father, preaching the undiluted
              word of God to every creature, baptizing them with the Holy Ghost
              and with power, having dominion and authority over the enemy and
              forces of darkness, manifesting God's glory in healing and loosing
              men from every form of bondage to the glory of God. John 14:12 12
              Verily, verily, I say unto you, He that believeth on me, the works
              that I do shall he do also; and greater works than these shall he
              do; because I go unto my Father. Mark 16:15-19 15 And he said unto
              them, go ye into all the world, and preach the gospel to every
              creature. 16 He that believeth and is baptized shall be saved; but
              he that believeth not shall be damned. 17 And these signs shall
              follow them that believe; In my name shall they cast out devils;
              they shall speak with new tongues; 18 They shall take up serpents;
              and if they drink any deadly thing, it shall not hurt them; they
              shall lay hands on the sick, and they shall recover.
            </p>
          </div>
          <div>
            <p>
              19 So then after the Lord had spoken unto them, he was received up
              into heaven, and sat on the right hand of God. Mathew 28:18-20 18
              And Jesus came and spake unto them, saying, all power is given
              unto me in heaven and in earth. 19 Go ye therefore, and teach all
              nations, baptizing them in the name of the Father, and of the Son,
              and of the Holy Ghost:{" "}
              {"teach…: or, make disciples, or, Christians of all nations"}
              Luke 4:18-19 18 The Spirit of the Lord is upon me, because he hath
              anointed me to preach the gospel to the poor; he hath sent me to
              heal the broken hearted, to preach deliverance to the captives,
              and recovering of sight to the blind, to set at liberty them that
              are bruised, 19 To preach the acceptable year of the Lord. Is.
              61:1-3
            </p>
          </div>
          <div>
            <p>
              1 The Spirit of the Lord GOD is upon me; because the LORD hath
              anointed me to preach good tidings unto the meek; he hath sent me
              to bind up the broken hearted, to proclaim liberty to the
              captives, and the opening of the prison to them that are bound; 2
              To proclaim the acceptable year of the LORD, and the day of
              vengeance of our God; to comfort all that mourn; 3 To appoint unto
              them that mourn in Zion, to give unto them beauty for ashes, the
              oil of joy for mourning, the garment of praise for the spirit of
              heaviness; that they might be called trees of righteousness, the
              planting of the LORD, that he might be glorified. Is. 33:15 15 He
              that walketh righteously, and speaketh uprightly; he that
              despiseth the gain of oppressions, that shaketh his hands from
              holding of bribes, that stoppeth his ears from hearing of blood,
              and shutteth his eyes from seeing evil;{" "}
              {"righteously: Heb. in righteousnesses"}{" "}
              {"uprightly: Heb. uprightnesses"} {"oppressions: or, deceits"}{" "}
              {"blood: Heb. bloods"}
            </p>
          </div>

          <div className="lg:-mt-14">
            <h3 className="font-semibold text-lg pb-2 uppercase">
              EVENTS OF 2018
            </h3>
            <p>
              God has been faithful as ever, but it was this year that the
              kingdom of darkness actually waged war against the Church, both
              from outside and from within. By May 17th to 20th, 2018, we had
              our 2nd year anniversary celebration tagged "True Worship" to the
              glory of God.
            </p>
          </div>
          <div>
            <h3 className="font-semibold text-lg pb-2 uppercase">
              2019 CELEBRATIONS
            </h3>
            <p>
              God have been awesome in Upright Life Fulfilling Ministry Int'l, I
              can boldly say that there is nobody that comes in contact with
              this Ministry that does not experience the working miracle of God.
              Space and time will not permit us to start enumerating what the
              Lord have been doing in our midst. To the glory of God, the theme
              for the year 2019 celebrations was: Jehovah Jireh. Jehovah
              over-do, Joy overflows!
            </p>
          </div>
          <div className="lg:-mt-20">
            <h3 className="font-semibold text-lg pb-2 uppercase">
              2020 CELEBRATIONS
            </h3>
            <p>
              Year 2020 was an awesome year, but we couldn't as a Church hold
              the anniversary celebrations because of COVID-19 lockdown.
            </p>
          </div>
          <div>
            <h3 className="font-semibold text-lg pb-2 uppercase">
              YEAR 2021 IS HERE
            </h3>
            <p>
              To the glory of God, He has brought us far and we give Him all the
              praise!
            </p>
          </div>
          <div className="lg:-mt-14">
            <h3 className="font-semibold text-lg pb-2 uppercase">YEAR 2022</h3>
            <p>
              Our God is still faithful. He said He will build His Church and
              the gates of hell shall not prevail against it. It was awesome in
              His presence as we celebrated the Church anniversary for this
              year. A lot of testimonies of answered prayers to the glory of
              God.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutChurch;
