import React, { useEffect, useState } from "react";
import GeneralBanner from "../Components/GeneralBanner";
import Aos from "aos";
import "aos/dist/aos.css";
import PaystackPop from "@paystack/inline-js";
import paymentLogo from "../Images/payment.png";

const Give = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");

  const submitForm = (e) => {
    e.preventDefault();

    const paystack = new PaystackPop();
    paystack.newTransaction({
      key: process.env.REACT_APP_paystackKey,
      amount: amount * 100,
      email,
      firstName,
      lastName,
      onSuccess() {
        setAmount("");
        setFirstName("");
        setEmail("");
        setLastName("");
      },
    });
  };

  return (
    <div>
      <GeneralBanner title="Give" pageName="Give" />

      <div className="Container mt-10 mb-20">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-10 text-center">
          {/*== Online Payment ==*/}
          <div data-aos="fade-up">
            <h2 className="font-medium text-lg md:text-xl pb-5 uppercase">
              Pay with Card
            </h2>

            <div>
              <form onSubmit={submitForm}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 payment-form">
                  <div>
                    <input
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>

                  <div>
                    <input
                      type="number"
                      placeholder="Enter Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="mt-4 flex justify-start">
                  <button type="submit" className="button w-full md:w-1/2 lg:w-full">
                    Pay Now
                  </button>
                </div>
              </form>
            </div>
            <img src={paymentLogo} alt="payment" className="h-8 mt-3" />
          </div>

          {/*== Bank transfer ==*/}
          <div data-aos="fade-up">
            <h2 className="font-medium text-lg md:text-xl pb-5 uppercase">
              Bank Transfer
            </h2>

            <table className="home-table">
              <thead className="bg-gray-200">
                <th>Account Number</th>
                <th>Bank Name</th>
                <th>Account Name</th>
              </thead>
              <tbody>
                <tr>
                  <td>0122840351</td>
                  <td>Wema Bank</td>
                  <td>Upright Life Fulfilling Ministry Int'l</td>
                </tr>
                <tr>
                  <td>0426142860</td>
                  <td>GT Bank</td>
                  <td>Upright Life Fulfilling Ministry Int'l</td>
                </tr>
                <tr>
                  <td>1021470889</td>
                  <td>UBA Plc</td>
                  <td>Upright Life Fulfilling Ministry Int'l</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Give;
