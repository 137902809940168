import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-primary pt-10 pb-8 text-white mt-10">
      <div className="Container">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="mt-2">
            <h3>About Us</h3>
            <p className="text-justify text-sm pt-2">
              Upright Life Fulfilling Ministry Int'l is a Ministry after God's
              heart, because in God and in His word alone we put all our trust.
              Beloved in Upright Life Fulfilling Ministry Int'l, God have been
              so gracious and kind to us, we have seen and have behold the
              great, glorious and wondrous things God have done in out midst
              when ever we gather.
            </p>
          </div>
          <div className="col-span-2">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:gap-3 gap-6">
              <div className="flex flex-col gap-2">
                <h3>Our Believe</h3>
                <Link to="#!">To make heaven</Link>
                <Link to="#!">Believe in one God</Link>
                <Link to="#!">Believe in Holy spirit</Link>

                <Link to="#!">Believe in the Word</Link>
                <Link to="#!">Holiness will be our lifestyle</Link>
              </div>

              <div className="flex flex-col gap-2">
                <h3>Quick Links</h3>
                <Link to="/give">Give</Link>
                <Link to="/gallery">Our Gallery</Link>
                <a
                  href="https://www.youtube.com/channel/UCVT_BFPfDcZ-aiZvVqMzVAg"
                  rel="noreferrer"
                  target="_blank"
                >
                  Watch Live
                </a>
                <Link to="/about-pastor">About our pastor</Link>
                <Link to="/about-church">About our church</Link>
              </div>
              <div className="flex flex-col gap-2">
                <h3>Contact Us</h3>

                <a href="tel:+234 802 796 1297">Call contact</a>
                <a href="#!">Email contact</a>
                <Link to="/contact">Contact form</Link>
                <a
                  href="https://api.whatsapp.com/send?phone=2348027961297&amp;text=Hello, I have a question about http%3A%2F%2Flocalhost%3A3000%2Fcontact"
                  target="_blank"
                  rel="noreferrer"
                >
                  WhatsApp contact
                </a>
                <Link to="/contact">Other contact details</Link>
              </div>
            </div>

            <hr className="mt-5" />
            <div className="flex text-sm gap-x-9 sm:flex-row flex-col-reverse mt-3 gap-y-3 md:gap-y-0">
              <span>
                © Upright Life Fulfilling Ministry Int'l 2022, All Rights Reserved.
              </span>
              <div className="flex items-center gap-3 social_media_icon">
                <a
                  href="https://www.facebook.com/ULFMCENTRE/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="ri-facebook-fill text-lg"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCVT_BFPfDcZ-aiZvVqMzVAg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="ri-youtube-line text-lg"></i>
                </a>
                <a href="#!" target="_blank" rel="noreferrer">
                  <i className="ri-twitter-fill text-lg"></i>
                </a>
                <a href="#!" target="_blank" rel="noreferrer">
                  <i className="ri-instagram-line text-lg"></i>
                </a>
                <a href="tel: +234 802 796 1297">
                  <i className="ri-phone-fill text-lg"></i>
                </a>
                <a href="#!">
                  <i className="ri-mail-line text-lg"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
