import React from "react";
import { NavLink } from "react-router-dom";

function SideDrawer({ show, click }) {
  const sideDrawerClass = ["sidedrawer"];

  if (show) {
    sideDrawerClass.push("show");
  }

  return (
    <div className={sideDrawerClass.join(" ")}>
      <i
        onClick={click}
        className="ri-close-line text-lg font-semibold flex justify-end pr-5 pt-4 pb-7 text-secondary"
      ></i>

      <ul className="sidedraw_links" onClick={click}>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/about-church">About Us</NavLink>
        </li>
        <li>
          <NavLink to="/about-pastor">About the Pastor</NavLink>
        </li>
        <li>
          <NavLink to="/gallery">Gallery</NavLink>
        </li>
        <li>
          <NavLink to="/give">Give</NavLink>
        </li>
        <li>
          <NavLink to="/contact">Contact Us</NavLink>
        </li>
      </ul>
    </div>
  );
}

export default SideDrawer;
