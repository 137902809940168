import React, { useEffect, useState } from "react";

const BackToTop = () => {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {backToTopButton && (
        <i
          onClick={scrollUp}
          className="ri-arrow-up-line text-xl z-50 cursor-pointer rounded-sm font-semibold text-white fa-arrow-up bg-secondary h-10 w-10 flex items-center justify-center fixed bottom-24 right-5 animate-bounce"
        ></i>
      )}
    </>
  );
};

export default BackToTop;
