import { Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout";
import ScrollToTop from "./Components/ScrollToTop";
import AboutChurch from "./Pages/AboutChurch";
import AboutPastor from "./Pages/AboutPastor";
import Contact from "./Pages/Contact";
import Gallery from "./Pages/Gallery";
import Give from "./Pages/Give";
import Home from "./Pages/Home";

function App() {
  return (
    <Layout>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-pastor" element={<AboutPastor />} />
          <Route path="/about-church" element={<AboutChurch />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/give" element={<Give />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </ScrollToTop>
    </Layout>
  );
}

export default App;
