import { useState, useEffect } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import Aos from "aos";
import "aos/dist/aos.css";
export function Sermon({ date, topic, bText, vId }) {
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <>
      <div data-aos="zoom-in">
        <h3 className="uppercase text-sm bg-primary inline-block text-neutral px-2 py-1">
          {date}
        </h3>
        <div className="bg-neutral py-5 px-3 font-medium group cursor-pointer rounded-tr rounded-b">
          <h3 className="uppercase pb-1">{topic}</h3>
          <div className="w-20 bg-gray-600 mb-1" style={{ height: 2 }} />
          <div>
            <h5 className="flex items-center gap-3">
              <span>Pst. Patience Ufford </span>
              <i
                className="ri-vidicon-fill group-hover:text-secondary cursor-pointer animate-bounce"
                onClick={() => setOpen(true)}
              ></i>
            </h5>
            <p className="text-sm pt-2">Text: {bText}</p>
          </div>
        </div>
      </div>

      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={vId}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
