import React, { useState } from "react";
import BackToTop from "./BackToTop";
import Footer from "./Footer";
import Backdrop from "./Navbar/Backdrop";
import Navbar from "./Navbar/Navbar";
import SideDrawer from "./Navbar/SideDrawer";
import StartTop from "./ScrollToTop";

const Layout = ({ children }) => {
  const [sideToggle, setSideToggle] = useState(false);
  return (
    <>
      <Navbar click={() => setSideToggle(true)} />
      <SideDrawer show={sideToggle} click={() => setSideToggle(false)} />
      <Backdrop show={sideToggle} click={() => setSideToggle(false)} />
      
      <BackToTop />
      <StartTop />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
