import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const GeneralBanner = ({ pageName, title }) => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className="banner text-white flex justify-center text-center items-center px-5">
      <div data-aos="zoom-in">
        <h3 className="text-2xl md:text-4xl font-medium pb-2">{title}</h3>
        <h4 className="font-medium">
          <Link to="/" className="text-secondary pr-1 hover:text-white">
            Home
          </Link>
          / {pageName}
        </h4>
      </div>
    </div>
  );
};

export default GeneralBanner;
