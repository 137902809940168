import React, { useEffect } from "react";
import intro from "../Images/introBg1.png";
import b1 from "../Images/b1.png";
import b2 from "../Images/b2.jpg";
import b3 from "../Images/b3.png";
import planBg from "../Images/Pbg.jpg";
import believeBg from "../Images/belief.jpg";
import { HomeBox } from "../Components/HomeBox";
import { Sermon } from "../Components/Sermon";
import { Link } from "react-router-dom";
import pastor from "../Images/pastor_passport.png";
import Aos from "aos";
import "aos/dist/aos.css";

import give from "../Images/give.jpg";
import Testimonials from "../Components/Testimonials";

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <>
      <div className="hero flex items-center justify-center text-center">
        <div className="Container text-neutral">
          <h1
            className="font-bold text-2xl md:text-3xl lg:text-5xl"
            data-aos="zoom-in"
          >
            Welcome to Upright Life
            <span className="pt-1 md:block"> Fulfilling Ministry Int'l</span>
          </h1>
          <p className="font-medium italic text-lg pt-3" data-aos="fade-up">
            We are glad you're here.
          </p>
        </div>
      </div>

      <div
        className="bg-cover bg-no-repeat bg-center py-20"
        style={{ background: `url(${intro})` }}
      >
        <div className="Container text-center" data-aos="fade-up">
          <h1 className="font-semibold text-xl md:text-3xl pb-3 uppercase">
            Welcome
          </h1>

          <div className="lg:px-32">
            <p className="text-justify md:text-center font-medium">
              Upright Life Fulfilling Ministry Int'l is a Ministry after God's
              heart, because in God and in His word alone we put all our trust.
              Beloved in Upright Life Fulfilling Ministry Int'l, God have been
              so gracious and kind to us, we have seen and have behold the
              great, glorious and wonderous things God have done in out midst
              when ever we gather. We have been so blessed and privileged to
              always enjoy the move of God's presence, power and the
              manifestation of His Glory.
            </p>
          </div>
        </div>
      </div>

      <div
        className="bg-cover bg-no-repeat bg-center py-12"
        style={{ background: `url(${planBg})` }}
      >
        <div className="Container">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <HomeBox
              bImage={b1}
              bTitle="Our Church"
              bDes="In Upright Life Fulfilling Ministry Int'l, we record diverse miracles on daily basis. God have been so faithful to us in manifesting his glory power and might..."
            />

            <HomeBox
              bImage={b2}
              bTitle="Our Mission"
              bDes="Raising men and women who are strong in faith, holding fast unto the mission and
              the finished work of our Lord Jesus Christ which was fulfilling the will of the Father..."
            />
            <HomeBox
              bImage={b3}
              bTitle="Our Vision"
              bDes="Fulfilling the original God&#39; s ordained destiny through Upright living with the help of
              the Holy Spirit, Helping people take next steps with Jesus and Making Jesus Known."
            />
            <div
              className="text-center px-5 py-10 text-white rounded-xl lg:hidden"
              style={{
                background: `url(${believeBg})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <h3 className="pb-5 font-semibold text-xl">Church Belief</h3>
              <p className="pb-6 text-sm">
                We believe in one God as the creator and ruler of the universe.
                We believe that God eternally exist in three persons: God the
                Father, God the Son and God the Holy Spirit. The three in one
                God as the Alpha and the Omega.
              </p>

              <Link to="/about-church" className="button">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* pastor */}

      <div
        className="bg-no-repeat bg-center pb-10 pt-7"
        style={{ background: `url(${planBg})` }}
      >
        <div className="Container">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="col-span-2 flex flex-col lg:flex-row relative">
              <img
                src={pastor}
                alt="pastor"
                className="w-28 h-28 lg:-mr-6 z-10 border rounded-full border-primary shadow shadow-primary"
              />
              <div className="bg-black py-7 px-8 lg:px-14 text-left rounded-3xl">
                <h3 className="text-white text-xl font-semibold">
                  Pst. Patience Ufford
                </h3>
                <h4 className="text-secondary py-2">Senior Pastor</h4>
                <p className="text-white text-sm text-justify">
                  The God of all grace anointing you with new oil and His
                  precious Spirit illuminating the Word as you read is our
                  prayer for you. May He grant you the ability to persuade
                  others with the vivid optimism He has given you to endure the
                  challenges you have faced and are now facing. We also hope
                  that the joy and serenity that God has promised to all of His
                  children who follow Him would be reflected in your home.
                </p>
              </div>
            </div>

            <div className="lg:flex hidden">
              <div
                className="text-center px-5 py-10 text-white rounded-xl"
                style={{
                  background: `url(${believeBg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <h3 className="pb-5 font-semibold text-xl">Church Belief</h3>
                <p className="pb-6 text-sm">
                  We believe in one God as the creator and ruler of the
                  universe. We believe that God eternally exist in three
                  persons: God the Father, God the Son and God the Holy Spirit.
                  The three in one God as the Alpha and the Omega.
                </p>

                <Link to="/about-church" className="button">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-neutral">
        <div className="Container py-10">
          <h2 className="text-center pb-8 text-lg md:text-2xl font-semibold uppercase">
            Stay connected with us
          </h2>

          <table className="home-table">
            <thead className="bg-gray-200">
              <tr>
                <th>S/N</th>
                <th>Day</th>
                <th>Activity</th>
                <th>Time</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>01</td>
                <td>Sundays</td>
                <td>Celebrations Service</td>
                <td>7:30am - 12:00 noon</td>
              </tr>

              <tr>
                <td>02</td>
                <td>Tuesdays</td>
                <td>Exploring the Logos Word</td>
                <td>6:00pm - 8:00pm</td>
              </tr>

              <tr>
                <td>03</td>
                <td>Thursdays</td>
                <td>Deliverance Service</td>
                <td>8:00am - 12:00 noon</td>
              </tr>

              <tr>
                <td>04</td>
                <td>Last Tuesday of every month</td>
                <td>Spiritual Counselling</td>
                <td>8:00am - 10:00am</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* sermon */}
      <div className="pt-12 pb-6 mt-14" style={{ background: "#3a3a3a" }}>
        <div className="Container">
          <h2 className="pb-8 font-bold text-lg md:text-2xl text-neutral text-center">
            SERMONS
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-16">
            <Sermon
              topic="Thanksgiving Sunday service"
              bText="Isaiah 61:1"
              date="Aug 8 2021"
              vId="_ePwUK0r_D0"
            />
            <Sermon
              topic="Anointing Service"
              bText="Philippians 3:12-14"
              date="Aug 1 2021"
              vId="fCSCB45USKc"
            />
            <Sermon
              topic="Sunday Service"
              bText="Philippians 3:12-14"
              date="Jul 25 2021"
              vId="gxCX0lRHPEw"
            />
          </div>

          <div className="flex justify-center mt-8">
            <a
              href="https://www.youtube.com/channel/UCVT_BFPfDcZ-aiZvVqMzVAg"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              <i className="fab fa-youtube pr-1"></i> Watch and comment on
              youtube
            </a>
          </div>
        </div>
      </div>
      {/* Give section */}
      <div className="bg-neutral mt-10 py-10">
        <div className="Container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-7">
            <div>
              <img src={give} alt="give" className="rounded" />
            </div>
            <div className="flex items-center">
              <div>
                <h2 className="font-semibold text-lg md:text-2xl uppercase">
                  Partner with Us
                </h2>
                <p className="pt-2 pb-3 text-left text-sm md:text-base">
                  Each of you should give what you have decided in your heart to
                  give, not reluctantly or under compulsion, for God loves a
                  cheerful giver.
                </p>
                <div className="flex">
                  <Link to="/give" className="button flex items-center gap-1">
                    <span>Give</span>
                    <i className="ri-arrow-right-line"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonial */}
      <Testimonials />
    </>
  );
};

export default Home;
