import { Link } from "react-router-dom";

export function HomeBox({ bImage, bTitle, bDes }) {
  return (
    <div
      className="text-center px-12 py-10 text-white rounded-md"
      style={{
        background: `url(${bImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <h3 className="pb-5 font-semibold text-xl">{bTitle}</h3>
      <p className="pb-6 text-sm">{bDes}</p>

      <Link to="/about-church" className="button">
        Learn More
      </Link>
    </div>
  );
}
