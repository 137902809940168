import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../Images/logo.png";
import "./style.css";

const Navbar = ({ click }) => {
  return (
    <div className="bg-primary py-1 w-full top-0 sticky z-50">
      <div className="Container flex justify-between items-center">
        <Link to="/">
          <img src={logo} alt="logo" className="h-12" />
        </Link>
        <div className="md:flex items-center gap-x-4 hidden">
          <NavLink to="/" className="nav-item">
            Home
          </NavLink>
          <div className="dropdown overflow-hidden">
            <NavLink to="#!" className="flex items-center gap-1 nav-item">
              <span>About</span>
              <i className="ri-arrow-down-s-line"></i>
            </NavLink>
            <div className="dropdown-content hidden absolute z-10 px-3 py-4">
              <div className="flex flex-col gap-2 bg-white rounded-md px-3 py-4">
                <NavLink
                  to="/about-church"
                  className="text-sm hover:text-secondary font-medium"
                >
                  About Us
                </NavLink>
                <NavLink
                  to="/about-pastor"
                  className="text-sm hover:text-secondary font-medium"
                >
                  About the Pastor
                </NavLink>
              </div>
            </div>
          </div>
          <NavLink to="/gallery" className="nav-item">
            Gallery
          </NavLink>
          <NavLink to="/give" className="nav-item">
            Give
          </NavLink>
          <NavLink to="/contact" className="nav-item">
            Contact Us
          </NavLink>
          <a
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCVT_BFPfDcZ-aiZvVqMzVAg"
            target="_blank"
            className="bg-secondary rounded px-2 text-neutral py-1 text-sm transition ease-in-out duration-300 hover:opacity-70"
          >
            Watch Live
          </a>
        </div>
        <div className="md:hidden">
          <i
            onClick={click}
            className="ri-menu-line text-neutral font-medium text-2xl"
          ></i>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
