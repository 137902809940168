import { useState, useEffect } from "react";
import { send } from "emailjs-com";
import validate from "../Components/validate";
import GeneralBanner from "../Components/GeneralBanner";
import Aos from "aos";
import "aos/dist/aos.css";

const initialState = {
  contactName: "",
  contactPhone: "",
  contactEmail: "",
  contactSubject: "",
  contactMessage: "",
};

const Contact = () => {
  const [person, setPerson] = useState(initialState);
  const [errorMsg, setErrorMsg] = useState(initialState);

  const handleChange = (e) => {
    setPerson({ ...person, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // check error onSubmit
    const formErrors = validate(person);
    setErrorMsg(formErrors);
    if (Object.keys(formErrors).length) {
      return;
    } else {
      send("gmail", "template_ru8l7ls", person, process.env.REACT_APP_API_KEY)
        .then((response) => {
          alert("Message sent!", response.status, response.text);
        })
        .catch((err) => {
          alert("Message failed try again!", err);
        });

      setPerson(initialState);
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <>
  <GeneralBanner title="Contact Us" pageName="Contact"/>
      {/* Main contact */}
      <div className="">
        <div className="Container pb-20 pt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4">
            {/* contact form */}
            <div data-aos="fade-up">
              <form onSubmit={onSubmit} className="contact-form">
                <h2 className="text-center text-2xl pb-5">SEND US A MESSAGE</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                  <div>
                    <input
                      type="text"
                      name="contactName"
                      id="contactName"
                      placeholder="Full name"
                      value={person.contactName}
                      onChange={handleChange}
                    />
                    <span className="block text-red-600 text-sm">
                      {errorMsg.contactName}
                    </span>
                  </div>
                  <div>
                    <input
                      type="tel"
                      name="contactPhone"
                      id="contactPhone"
                      placeholder="Phone"
                      value={person.contactPhone}
                      onChange={handleChange}
                    />
                    <span className="block text-red-600 text-sm">
                      {errorMsg.contactPhone}
                    </span>
                  </div>
                  <div>
                    <input
                      type="email"
                      name="contactEmail"
                      id="contactEmail"
                      placeholder="Enter Email"
                      value={person.contactEmail}
                      onChange={handleChange}
                    />
                    <span className="block text-red-600 text-sm">
                      {errorMsg.contactEmail}
                    </span>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="contactSubject"
                      id="contactSubject"
                      placeholder="Subject"
                      value={person.contactSubject}
                      onChange={handleChange}
                    />
                    <span className="block text-red-600 text-sm">
                      {errorMsg.contactSubject}
                    </span>
                  </div>
                </div>
                <textarea
                  name="contactMessage"
                  id="contactMessage"
                  className="w-full mt-4 pl-2 pt-2 h-28 resize-none focus:outline-none rounded-sm border border-black placeholder:text-black"
                  placeholder="Message"
                  value={person.contactMessage}
                  onChange={handleChange}
                ></textarea>
                <span className="block text-red-600 text-sm">
                  {errorMsg.contactMessage}
                </span>
                <div className="flex justify-center mt-2">
                  <button
                    type="submit"
                    className="bg-primary text-neutral py-2 rounded-md text-lg w-full focus:outline-none hover:opacity-70"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>

            {/* contact detail */}
            <div data-aos="fade-up">
              <h2 className="text-center text-2xl pb-5">REACH US</h2>
              <div className="text-center font-medium text-base lg:text-lg border border-black rounded-md py-4">
                <div>
                  <div className="flex justify-center pt-1">
                    <i className="ri-map-pin-line text-xl h-9 w-9 rounded-full bg-primary flex items-center justify-center text-neutral"></i>
                  </div>
                  <span className="text-sm">
                    Decolossus Arena Hall plot 137 Bola Ahmed
                    <span className="block">
                      Bogije junction Ibeju Lekki, Off Epe, Express Way Lagos.
                    </span>
                  </span>
                </div>
                <div className="my-5">
                  <div className="flex justify-center">
                    <i className="ri-phone-line text-xl h-9 w-9 rounded-full bg-primary flex items-center justify-center text-neutral"></i>
                  </div>
                  <span className="flex flex-wrap justify-center gap-4 pt-1 text-sm">
                    <a
                      href="tel:+2348027961297"
                      className="hover:text-secondary"
                    >
                      +234 802 7961 297
                    </a>{" "}
                    <a
                      href="tel:+234 803 5604 891"
                      className="hover:text-secondary"
                    >
                      +234 803 5604 891
                    </a>
                  </span>
                </div>

                <div>
                  <div className="flex justify-center">
                    <i className="ri-mail-line text-xl h-9 w-9 rounded-full bg-primary flex items-center justify-center text-neutral"></i>
                  </div>
                  <a
                    href="mailto:info@uprightlifeministry.org"
                    className="hover:text-secondary text-sm"
                  >
                    info@uprightlifeministry.org
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Google map */}
      <iframe
        width="100%"
        height="500"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=plot%20137%20Bola%20Bogije%20junction%20Ibeju%20Lekki,%20Off%20Epe,%20Express%20Way%20Lagos&t=&z=13&ie=UTF8&iwloc=&output=embed"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        title="google map"
      ></iframe>
    </>
  );
};

export default Contact;
