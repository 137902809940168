import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sanityClient from "../Client";
const Testimonials = () => {
  const [postData, setPostData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    sanityClient
      .fetch(
        `*[_type == "post"]{name,slug,mainImage{asset->{_id,url},alt}, body}`
      )
      .then((data) => setPostData(data))
      .catch((err) => console.log(err));
    setLoading(false);
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    cssEase: "linear",
    dots: true,
    vertical: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="Container my-20">
      <h2 className="pb-8 font-bold text-lg md:text-2xl text-center">
        OUR TESTIMONIALS
      </h2>

      {/* {postData === 0 && ( */}
        <div className="hidden justify-center">
          <button className="button">Coming soon..</button>
        </div>
      {/* )} */}

      {/* {loading && <h5>Loading...</h5>} */}
      {/* write a condition to check length it must be more === or more that 3 */}
      <div className="">
        <Slider {...settings}>
          {postData &&
            postData.map((item) => {
              return (
                <div key={item.name}>
                  <div className="bg-white py-4 px-2 overflow-hidden text-center border border-primary rounded-md mx-3 mb-5">
                    <div className="flex justify-center">
                      <svg
                        className="w-8 h-8 mb-4 text-primary"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                        ></path>
                      </svg>
                    </div>
                    <p className="pb-2 text-sm text-center">
                      {item.body && item.body.map((x) => x.children[0].text)}
                    </p>
                    <div className="flex justify-center my-3">
                      <img
                        className="h-16 rounded-md"
                        src={item.mainImage.asset.url}
                        alt="testimonial"
                      />
                    </div>
                    <h3 className="font-medium text-base">{item.name}</h3>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
